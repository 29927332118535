import React from 'react';

import Layout from '../components/layout';
import { Row, Heading, Frame, Line } from 'arwes';
import WorkEntry from '../components/workEntry';

import Loading from '../components/loading';

class Leadership extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
          loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        });
    }

    render() {
        if (this.state.loading) {
            return (<Loading />);
        }
        
        return (<Layout>
            <div style={{ maxWidth: "400px", paddingBottom: "40px" }}>
                <Frame animate layer='primary' corners={4}>
                    {anim => (
                        <div style={{paddingTop: "10px"}}>
                            <Heading animate show={anim.entered} node='h1'>Leadership</Heading>
                        </div>
                    )}
                </Frame>
            </div>

            <div style={{ padding: "20px" }}>
                <Row>
                    <WorkEntry position="President and Cofounder" company="Harvard College Cyber Club" start="Dec 2016" end="Aug 2019">
                        <li>Created 15 lectures & labs (Bash, DNS, Splunk, AD, etc.) to teach 15 new members per semester</li>
                        <li>Led a novice team to beat 3 veteran teams at the largest intercollegiate cybersecurity competition</li>
                        <li>Coordinated guest lectures from multiple fields, including the FBI and Goldman Sachs</li>
                        <li>Worked closely with members of Harvard University IT to teach Linux and Windows admin basics</li>
                    </WorkEntry>

                    <WorkEntry position="Director of Technical Development" company="The Harvard Crimson" start="Jan 2017" end="Dec 2019">
                        <li>Created 10 auto-graded assignments and improved on-boarding retention rates 5-fold</li>
                        <li>Since joining, the board has doubled in size and annual webpage views has increased by 2 million</li>
                        <li>Established new, career-oriented traditions for helping new members find jobs, including technical interview workshops</li>
                    </WorkEntry>
                    <Line layer="control" />

                </Row>
            </div>
        </Layout>);
    }
}

export default Leadership;